import axios from 'axios'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'

import { ComponentRenderer } from 'components'

export async function getServerSideProps({ query, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=100, stale-while-revalidate=59'
  )

  const { pageKey = 'defaultPageKey' } = query

  const fetchDataContent = async (payload) => {
    const baseLocalAPIPath = process.env.NEXT_PUBLIC_BASE_URL
    const NEXT_API_URL = `${baseLocalAPIPath}/api`

    return await axios({
      url: `${NEXT_API_URL}/content/v2/storage/get/content`,
      params: { ...payload },
    }).then((res) => {
      return res.data
    })
  }
  const payload = {
    pageName: pageKey,
    contentType: 'DataContent',
  }
  const { data } = await fetchDataContent(payload)

  const { schema, meta = {} } = data || {}

  return {
    props: {
      schema,
      meta,
      pageKey,
    },
  }
}

export const CartoolSite = ({
  schema,
  config = {},
  pageKey,
  meta = {
    title: 'CAR Tool',
    description: 'CAR Tool Description Meta',
  },
}) => {
  const router = useRouter()
  const id = router.asPath.match(/#([a-z0-9]+)/gi)

  // DO NOT REMOVE TODO: placeholder for config example,
  // const testConfig = {
  //   pusatPanggilanSection: {
  //     props: {
  //       onClick: 'changeNode(testEvent, props, {"content":"value"})',
  //     },
  //   },
  // }

  React.useEffect(() => {
    if (id && id.length > 0) {
      const element = document.getElementById(id[0].replace('#', ''))
      if (element) {
        const headerOffset = 200
        const elementPosition =
          element.getBoundingClientRect().top - headerOffset
        const offsetPosition = elementPosition + window.pageYOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }
  }, [id])

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        {/* Open Graph */}
        {/* <meta property="og:type" content={meta.type} /> */}
        {/* <meta property="og:site_name" content={meta.siteName} /> */}
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        {/* <meta name="image" property="og:image" content={meta.image} /> */}

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:site" content="@Kemdikbud_RI" /> */}
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        {/* <meta name="twitter:image" content={meta.image} /> */}
      </Head>

      {pageKey === 'ult-kemdikbud' && (
        <>
          <Script
            id="scroll-behaviour"
            dangerouslySetInnerHTML={{
              __html: `
              document.querySelectorAll('a[href^="#"]')
                .forEach(el => el.addEventListener('click', (e) => {
                  e.preventDefault();
                  const target = document.querySelector(el.attributes.href.value)
                  target.scrollIntoView({ behavior: "smooth", block: "center" });
                }))
          `,
            }}
          />
          <Script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=f7229f3d-b376-4dd5-a3f0-3870beaf7f20"
          />
          <Script
            id="zE"
            dangerouslySetInnerHTML={{
              __html: `
                function waitForElement(selector, parent = window.document) {
                  return new Promise(function (resolve) {
                    (function findWidget() {
                      setTimeout(function () {
                        const widget = parent.querySelector(selector);
                        if (widget != null) {
                          resolve(widget);
                        } else {
                          findWidget();
                        }
                      }, 60);
                    })();
                  });
                }

                function customWidget(form) {
                  const containerFormLabel = ((form.querySelectorAll('div')[0]).querySelectorAll('div')[0]).querySelectorAll('div')[0]
                  const labelForm = containerFormLabel.querySelectorAll('label')
                  labelForm[0].innerHTML = '<div>Nama</div>'
                }

                setTimeout(() => {
                  window.zE('messenger:set', 'locale', 'id')
                    var el = document.getElementsByTagName('iframe')[1]
                    el.setAttribute('id', 'webWidget')
                    
                    waitForElement('#webWidget')
                      .then(function (iframe) {
                        return waitForElement('form', iframe.contentDocument)
                      })
                      .then(function (form) {
                        customWidget(form)
                      })
                }, 1000)
              `,
            }}
          />
        </>
      )}

      <ComponentRenderer data={schema} nodeConfig={config} />
    </>
  )
}

export default CartoolSite
